import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Features from "../components/features"
import Hero from "../components/hero"

const IndexPage = () => (
  <Layout>
    <SEO title="Property Investing Made Easy" />
    <Hero />
    <Features />
  </Layout>
)

export default IndexPage
