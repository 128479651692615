import React from "react"
import { Heading } from "insites-ui"
import "../styles/hero.css"

const Hero = () => (
  <header className="hero-header">
    <div className={"container"}>
      <div className={"row"}>
        <div className={"col-xs-12"}>
          <div className={"text-center header-content"}>
            <Heading>
              The first <span>FREE</span> robust, easy-to-use property tracking
              software for investors
            </Heading>
            <h2>
              Forget the paperwork and ditch the spreadsheets.
              <br />
              Make your investment property tracking simple and easy.
            </h2>
            <a href={"https://app.investmentpropertytracker.com.au/sign-up"}>
              <button
                type="button"
                className="btn btn-primary btn-rounded btn-lg"
              >
                Get started for free
              </button>
            </a>
          </div>
        </div>
        <div className={"col-xs-12 hero-image"}></div>
      </div>
    </div>
  </header>
)

export default Hero
