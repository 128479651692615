import React from "react"
import {
  Cloud,
  DollarSign,
  AlertCircle,
  TrendingUp,
  Search,
  FileText,
} from "react-feather"
import { Heading, Box, Text } from "insites-ui"
import "../styles/features.css"

const Features = () => (
  <section className="features-section">
    <div className={"section-heading"}>
      <Heading as="h2">
        Features that will change the way you manage your property investments
      </Heading>
    </div>
    <div className={"col-lg-12 my-auto features-container"}>
      <div className={"container-fluid"}>
        <div className={"row"}>
          <div className={"col-lg-4 col-md-6 py-3"}>
            <Box
              backgroundColor="#ffffff"
              boxShadow="0 0.5rem 1rem rgba(0, 0, 0, 0.05)"
              borderRadius="0.5rem"
              padding="2rem"
              height="100%"
            >
              <Heading as="h4" size="md">
                <DollarSign color="#0040ff" />
                Transactions
              </Heading>
              <Text>
                Track your investment property income and expenses with a
                real-time ledger that let&lsquo;s you do away with mountains of
                paperwork.
              </Text>
            </Box>
          </div>
          <div className={"col-lg-4 col-md-6 py-3"}>
            <Box
              backgroundColor="#ffffff"
              boxShadow="0 0.5rem 1rem rgba(0, 0, 0, 0.05)"
              borderRadius="0.5rem"
              padding="2rem"
              height="100%"
            >
              <Heading as="h4" size="md">
                <Search color="#0040ff" />
                Insights
              </Heading>
              <Text>
                Project your income, expenses and capital growth to see your
                financial position up to 30 years in the future.
              </Text>
            </Box>
          </div>
          <div className={"col-lg-4 col-md-6 py-3"}>
            <Box
              backgroundColor="#ffffff"
              boxShadow="0 0.5rem 1rem rgba(0, 0, 0, 0.05)"
              borderRadius="0.5rem"
              padding="2rem"
              height="100%"
            >
              <Heading as="h4" size="md">
                <AlertCircle color="#0040ff" />
                Risk management
              </Heading>
              <Text>
                Use our proprietary tools to manage risk by sensitising your
                cashflows for unexpected events like higher interest rates and
                tenant vacancies.
              </Text>
            </Box>
          </div>
          <div className={"col-lg-4 col-md-6 py-3"}>
            <Box
              backgroundColor="#ffffff"
              boxShadow="0 0.5rem 1rem rgba(0, 0, 0, 0.05)"
              borderRadius="0.5rem"
              padding="2rem"
              height="100%"
            >
              <Heading as="h4" size="md">
                <FileText color="#0040ff" />
                Reporting
              </Heading>
              <Text>
                We make it easy for your accountant by producing detailed
                property reports by financial year so you can save money during
                tax time.
              </Text>
            </Box>
          </div>
          <div className={"col-lg-4 col-md-6 py-3"}>
            <Box
              backgroundColor="#ffffff"
              boxShadow="0 0.5rem 1rem rgba(0, 0, 0, 0.05)"
              borderRadius="0.5rem"
              padding="2rem"
              height="100%"
            >
              <Heading as="h4" size="md">
                <TrendingUp color="#0040ff" />
                Forecast cashflows
              </Heading>
              <Text>
                Project your property cashflows, equity and debt up 30 years
                into the future with our dynamic dashboard.
              </Text>
            </Box>
          </div>
          <div className={"col-lg-4 col-md-6 py-3"}>
            <Box
              backgroundColor="#ffffff"
              boxShadow="0 0.5rem 1rem rgba(0, 0, 0, 0.05)"
              borderRadius="0.5rem"
              padding="2rem"
              height="100%"
            >
              <Heading as="h4" size="md">
                <Cloud color="#0040ff" />
                Cloud based
              </Heading>
              <Text>
                Access your portfolio anywhere and anytime. All our products are
                cloud-based and our developers are working hard to constantly
                rollout new features.
              </Text>
            </Box>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Features
